






import Vue from 'vue'
import { mapGetters } from 'vuex'

import Maintenance from '@/components/templates/c/maintenance/500ResponseView.vue'

export default Vue.extend({
  components: { Maintenance },
  layout: 'none',
  mounted() {
    // this.getSystemInfo()
  },
  computed: {
    ...mapGetters('app', ['isSystemMaintenance']),
  },
  methods: {
    async getSystemInfo() {
      await this.$store.dispatch('app/getSystemStatus')
      if (!this.isSystemMaintenance) {
        this.$router.push('')
      }
    },
  },
})
