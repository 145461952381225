































import { defineComponent, ref } from '@vue/composition-api'

import config from '@/utils/config'
import { displayMaintenanceWord1, displayMaintenanceWord2 } from '@/utils/mixin'

/**
 * メンテ・障害発生時の画面
 */
export default defineComponent({
  props: {
    /**
     * 種類（0:メンテ, 1:障害）
     */
    type: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const { supportML } = config
    const maintenanceWord1 = ref(displayMaintenanceWord1().split('\\n'))
    const maintenanceWord2 = ref(displayMaintenanceWord2().split('\\n'))
    return {
      supportML,
      maintenanceWord1,
      maintenanceWord2,
    }
  },
})
